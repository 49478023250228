import axios, { AxiosError } from "axios"
import { useState } from "react"
import { errorSnack } from "../../components/Notistack/ThemedSnackbars"
import { getDocumentSignedPutUrl, getFileUploadSignedPutUrl } from "../../data/api"

export enum DocumentModelType {
  Asset = "asset",
  Project = "project",
  User = "user",
  Vendor = "vendor",
}

export type FileUpload = {
  file: File
  blob: Blob
}

export const fileUpload = async (
  key: string,
  type: string,
  file: Blob
): Promise<
  | {
      expiration: Date
      fileId: string
      objectKey: string
      url: string
    }
  | undefined
> => {
  try {
    const { expiration, fileId, objectKey, url } = await getFileUploadSignedPutUrl(key, type)

    await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": type,
      },
      body: file,
    })

    return { expiration: new Date(expiration), fileId, objectKey, url }
  } catch (e) {
    errorSnack("There was a problem uploading your file. Please try again.")
  }
}

export const documentUpload = async (
  modelType: DocumentModelType,
  modelId: string,
  key: string,
  type: string,
  file: Blob,
  expiresAt?: Date | string
): Promise<
  | {
      expiration: Date
      fileId: string
      objectKey: string
      url: string
      modelId: string
      modelType: DocumentModelType
      expiresAt?: Date | string
    }
  | undefined
> => {
  try {
    const { expiration, fileId, objectKey, url } = await getDocumentSignedPutUrl(
      modelType,
      modelId,
      key,
      type,
      expiresAt
    )

    await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": type,
      },
      body: file,
    })

    return { expiration: new Date(expiration), fileId, objectKey, url, modelType, modelId, expiresAt }
  } catch (e) {
    errorSnack("There was a problem uploading your file. Please try again.")
  }
}

export const useFileUpload = () => {
  const [abortController, setAbortController] = useState<AbortController>()
  const [uploadProgress, setUploadProgress] = useState(0)
  const [uploadError, setError] = useState<Error>()
  const [uploaded, setUploaded] = useState(false)

  return {
    uploadProgress,
    uploaded,
    uploadError,
    uploadToS3: async (signedUrl: string, file: Blob) => {
      try {
        const controller = new AbortController()
        setAbortController(controller)

        await axios.put(signedUrl, file, {
          headers: { "Content-Type": file.type },
          onUploadProgress: (e) => setUploadProgress(e?.total ? Math.round((e.loaded / e.total) * 100) / 100 : 0),
          signal: controller.signal,
        })

        setUploaded(true)
      } catch (error) {
        if (error instanceof AxiosError) setError(error.response?.data)
        else if (error instanceof Error) setError(error)
        else setError(new Error("something went wrong"))
      }
    },
    cancelUpload: async () => {
      abortController && abortController.abort()
    },
  }
}

import { ChipSelectOption } from "../../components/Formik/ChipSelect"
import { Role } from "../../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../../types/helpers"
import { sortByFieldAscending } from "../sorts/sortByFieldAscending"

export function roleSelectOptions(role?: PickPlus<Role, "id" | "description" | "name">[] | null): ChipSelectOption[] {
  const roles = role || []
  return roles
    .map((r) => ({
      value: r.id,
      label: r.name || "",
      description: r.description || "",
      descriptionLink: "/organization/roles",
    }))
    .sort(sortByFieldAscending((option: ChipSelectOption) => option.label))
    .reverse()
}

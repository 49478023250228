import { Button, Divider, Typography } from "@mui/material"

import { Form, Formik, FormikHelpers } from "formik"
import { FC, useContext, useState } from "react"
import { useQuery } from "urql"
import * as Yup from "yup"
import {
  CreateUserFormWithOrgIdQuery,
  CreateUserFormWithoutOrgIdQuery,
  PayType,
  Role,
  useCreateUserMutation,
  useUserEditMutation,
} from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { dataURLToBlob } from "../../../helpers/files/dataURLToBlob"
import { fileUpload } from "../../../helpers/files/fileUpload"
import { getDataUrlForImage } from "../../../helpers/files/getDataUrlForImage"
import { roleSelectOptions } from "../../../helpers/permissions/roleHelpers"
import { useHandleError } from "../../../hooks/useHandleError"
import { warnOnExitStoreActions } from "../../../stores/warnOnExit"
import { AdminRoleName, TeamMemberRoleName } from "../../../types/Role"
import { PickPlus } from "../../../types/helpers"
import { DevelopmentFeatureFlag } from "../../DevelopmentFeatureFlag"
import { ChipSelect } from "../../Formik/ChipSelect"
import { CreatableAutocomplete } from "../../Formik/CreatableAutocomplete"
import { RadioGroup } from "../../Formik/RadioGroup"
import { Switch } from "../../Formik/Switch"
import { TextField } from "../../Formik/TextField"
import { UserBillingClassificationSelect } from "../../Formik/UserBillingClassificationSelect"
import { WorkersCompCodeSelect } from "../../Formik/WorkersCompCodeSelect"
import { ImageUpload } from "../../ImageUpload"
import { errorSnack, successSnack } from "../../Notistack/ThemedSnackbars"
import { RenderIf } from "../../RenderIf"
import { SingleDrawerContext } from "../Drawer/components/Elements/Drawer"
import { DrawerFooter } from "../Drawer/components/Elements/DrawerFooter"

type Values = {
  firstName: string
  lastName: string
  title: string
  email: string
  phoneNumber: string
  phoneNumberExt: string
  roleId: string
  billingClassificationId: string
  workersCompCodeId: string
  companyUserId: string
  payType: PayType
  payRate: string
  sendInvite: boolean
}

type Props = {
  selectedRole?: PickPlus<Role, "id">
  createAdmin?: boolean
  organizationId?: string
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string().trim().required("Required").label("First Name"),
  lastName: Yup.string().trim().required("Required").label("Last Name"),
  title: Yup.string().trim().label("Title"),
  email: Yup.string()
    .trim()
    .email()
    .when("sendInvite", {
      is: true,
      then: (schema) => schema.required().label("Email"),
    })
    .label("Email"),
  roleId: Yup.string().required(),
  phoneNumber: Yup.string()
    .trim()
    .nullable()
    .label("Phone Number")
    .matches(/\(([2-9])(\d{2})\) (\d{3})-(\d{4})$/, {
      message: "Invalid phone number format",
    }),
  phoneNumberExt: Yup.string()
    .trim()
    .nullable()
    .label("Extension")
    .matches(/(^[0-9]+)$/, { message: "Invalid number format" }),
  billingClassificationId: Yup.string().nullable().label("Billing Classification"),
  workersCompCodeId: Yup.string().nullable().label("Workers Comp Code"),
  companyUserId: Yup.string().trim().label("Company User ID"),
  payType: Yup.mixed<PayType>().oneOf(Object.values(PayType), "Invalid Pay Type").required(),
  payRate: Yup.string(),
  sendInvite: Yup.boolean(),
})

const CreateUserFormWithOrgIdDocument = graphql(`
  query CreateUserFormWithOrgId($organizationId: String!) {
    organization(id: $organizationId) {
      id
      notificationSettings
      roles {
        id
        name
        description
      }
    }
    getJobTitles
  }
`)

const CreateUserFormWithoutOrgIdDocument = graphql(`
  query CreateUserFormWithoutOrgId {
    myOrganization {
      id
      notificationSettings
      roles {
        id
        name
        description
      }
    }
    getJobTitles
  }
`)

export const CreateUserForm: FC<Props> = ({ organizationId, selectedRole, createAdmin }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [{ data: dataWithId }] = useQuery({
    query: CreateUserFormWithOrgIdDocument,
    variables: { organizationId: organizationId ?? "" },
    pause: !organizationId,
  })
  const [{ data: dataForUser }] = useQuery({ query: CreateUserFormWithoutOrgIdDocument, pause: !!organizationId })

  const [userImageSrc, setUserImageSrc] = useState<string>("")

  const [, createUserMutation] = useCreateUserMutation()
  const [{ error: editUserError }, editUserMutation] = useUserEditMutation()
  useHandleError(editUserError, "There was an error saving the user's image.")

  const { handleClose } = useContext(SingleDrawerContext)
  const { setHasUnsavedChanges } = warnOnExitStoreActions
  const [_isDirty, setIsDirty] = useState(false)

  const data = dataWithId || dataForUser || null
  const organization =
    (data as CreateUserFormWithOrgIdQuery)?.organization ||
    (data as CreateUserFormWithoutOrgIdQuery)?.myOrganization ||
    null

  const handleSubmit = async (values: Values, { resetForm, setFieldError }: FormikHelpers<Values>) => {
    setIsLoading(true)

    const {
      email,
      firstName,
      lastName,
      title,
      phoneNumber,
      phoneNumberExt,
      billingClassificationId,
      workersCompCodeId,
      companyUserId,
      payRate,
      payType,
      roleId,
      sendInvite,
    } = values

    const result = await createUserMutation({
      user: {
        organizationId: organization.id,
        email,
        roles: [roleId],
        firstName,
        lastName,
        jobTitle: title,
        phoneNumber,
        phoneNumberExt,
        billingClassificationId,
        workersCompCodeId,
        companyUserId,
        payRate: payRate.replace(/[^0-9.]/g, ""),
        payType,
      },
      sendInvite,
    })

    if (result.error) {
      const graphqlErrors = result.error.graphQLErrors
      if (graphqlErrors.filter((e) => e.message.startsWith("Email"))) {
        graphqlErrors.forEach((e) => e.message.startsWith("Email") && setFieldError("email", e.message))

        errorSnack(result.error.graphQLErrors.map((e) => e.message).join(", "))
      } else {
        console.error("error", result.error)
        errorSnack("Error creating new user")
      }
    } else {
      if (!userImageSrc) {
        successSnack(`Creation successful${sendInvite ? ", invite sent" : ""}.`)
      } else if (result.data?.createUser?.id) {
        const imageBlob = dataURLToBlob(userImageSrc)
        const uploadedFile = await fileUpload(`user-image/${result.data.createUser.id}`, "image/webp", imageBlob)
        const image = uploadedFile?.objectKey || undefined

        const userEditResult = await editUserMutation({
          user: {
            id: result.data.createUser.id,
            organizationId: organization.id,
            image,
          },
          sendInvite: false,
        })

        if (userEditResult.error) {
          errorSnack("Failure finalizing new user")
        } else {
          successSnack(`Creation successful${sendInvite ? ", invite sent" : ""}.`)
        }
      }
      setHasUnsavedChanges(false)
      resetForm()
      handleClose()
    }

    setIsLoading(false)
  }

  const defaultRole = createAdmin ? AdminRoleName : TeamMemberRoleName

  return (
    <>
      {organization && (
        <Formik
          enableReinitialize
          initialValues={{
            firstName: "",
            lastName: "",
            title: "",
            email: "",
            phoneNumber: "",
            phoneNumberExt: "",
            roleId: selectedRole?.id || organization.roles.find((role) => role.name === defaultRole)!.id,
            billingClassificationId: "",
            workersCompCodeId: "",
            companyUserId: "",
            payType: PayType.Hourly,
            payRate: "$0.00",
            sendInvite: organization?.notificationSettings?.["USER_INVITE"] ?? false,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, dirty }) => {
            setTimeout(
              () =>
                setIsDirty((previous) => {
                  if (previous !== dirty) {
                    setHasUnsavedChanges(dirty)
                    return dirty
                  }
                  return previous
                }),
              0
            )

            return (
              <Form className="flex flex-col lg:w-2/3" noValidate>
                <ImageUpload
                  onFileSelected={([file]) => {
                    if (file) {
                      getDataUrlForImage(file, (dataUrl) => setUserImageSrc(dataUrl), 600, 600)
                    }
                  }}
                  onDelete={() => {
                    setUserImageSrc("")
                  }}
                  imageSrc={userImageSrc}
                  accept="accept"
                />
                <div className="grid grid-cols-12 gap-x-5 mt-5">
                  <TextField
                    required
                    name="firstName"
                    label="First Name"
                    disabled={isLoading}
                    className="col-span-12 md:col-span-6"
                  />

                  <TextField
                    required
                    name="lastName"
                    label="Last Name"
                    disabled={isLoading}
                    className="col-span-12 md:col-span-6"
                  />

                  <CreatableAutocomplete
                    name="title"
                    label="Job Title"
                    disabled={isLoading}
                    options={(data?.getJobTitles || []).map((title: string) => ({ label: title, id: title }))}
                    className="col-span-12"
                  />

                  <DevelopmentFeatureFlag name="Billing Classifications">
                    <RenderIf permissionsInclude="timeEntry:export">
                      <div className="col-span-12 md:col-span-6 pb-6">
                        <UserBillingClassificationSelect name="billingClassificationId" />
                      </div>
                    </RenderIf>
                  </DevelopmentFeatureFlag>

                  <DevelopmentFeatureFlag name="Workers Comp Code">
                    <RenderIf permissionsInclude="timeEntry:export">
                      <div className="col-span-12 md:col-span-6 pb-6">
                        <WorkersCompCodeSelect name="workersCompCodeId" />
                      </div>
                    </RenderIf>
                  </DevelopmentFeatureFlag>

                  <TextField
                    name="companyUserId"
                    label="Company User ID"
                    disabled={isLoading}
                    className="col-span-12 md:col-span-6"
                  />

                  <DevelopmentFeatureFlag name="Pay Rate">
                    <RenderIf permissionsInclude="payRate:update">
                      <div className="col-span-12">
                        <Typography variant="h5">Payment Details</Typography>
                        <Divider className="mb-5" />

                        <div className="flex items-center gap-3 mb-3">
                          <Typography className="font-semibold text-gray-800">Pay type</Typography>
                          <RadioGroup
                            name="payType"
                            options={[
                              { label: "Hourly", value: PayType.Hourly },
                              { label: "Salary", value: PayType.Salary },
                            ]}
                          />
                        </div>
                        <TextField fullWidth name="payRate" label="Pay Rate" disabled={isLoading} type="money" />
                      </div>
                    </RenderIf>
                  </DevelopmentFeatureFlag>

                  {!selectedRole && !createAdmin && (
                    <div className="col-span-12">
                      <ChipSelect
                        required
                        name="roleId"
                        options={roleSelectOptions(organization.roles)}
                        label="App Role"
                      />
                    </div>
                  )}
                  <TextField
                    name="phoneNumber"
                    label="Phone"
                    disabled={isLoading}
                    placeholder="(888) 888-8888"
                    type="tel"
                    className="col-span-12 md:col-span-8"
                  />

                  <TextField
                    name="phoneNumberExt"
                    label="Ext."
                    disabled={isLoading}
                    className="col-span-12 md:col-span-4"
                    inputProps={{ maxLength: 6 }}
                  />
                  <TextField
                    name="email"
                    label="Email"
                    disabled={isLoading}
                    type="email"
                    required={values.sendInvite}
                    placeholder="you@example.com"
                    className="col-span-12 mb-0 pb-0"
                  />
                  <div className="col-span-12 mt-0 pt-0">
                    <Switch name="sendInvite" label="Send email invite for app access." />
                  </div>
                </div>
                <DrawerFooter>
                  <div className="flex flex-wrap items-center gap-x-8 gap-y-3">
                    <Button
                      type="submit"
                      sx={{ boxShadow: 0 }}
                      size="large"
                      variant="contained"
                      disabled={isLoading}
                      className="bg-blue-600 rounded font-[600] text-base px-8"
                    >
                      {values.sendInvite ? "Save & invite" : `Save ${createAdmin ? "admin" : "user"}`}
                    </Button>
                    <Button
                      size="large"
                      type="button"
                      onClick={handleClose}
                      disabled={isLoading}
                      className="rounded font-[600] text-base px-8"
                    >
                      Cancel
                    </Button>
                  </div>
                </DrawerFooter>
              </Form>
            )
          }}
        </Formik>
      )}
    </>
  )
}

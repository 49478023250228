import { Autocomplete, CircularProgress, TextField } from "@mui/material"
import { useField } from "formik"
import { FC } from "react"
import { useQuery } from "urql"
import { graphql } from "../../graphql/generated/gql"

const WorkersCompCodeSelectDocument = graphql(`
  query WorkersCompCodeSelect {
    workersCompCodes {
      id
      name
      ncciCode
    }
  }
`)

type Props = {
  disabled?: boolean
  name: string
}

export const WorkersCompCodeSelect: FC<Props> = ({ disabled, name }) => {
  const [{ data, fetching }] = useQuery({ query: WorkersCompCodeSelectDocument })
  const [field, { touched, error }, { setValue }] = useField(name)

  const errorShouldBeDisplayed = touched && !!error && !disabled

  const options = data?.workersCompCodes || []

  const selectedOption = options.find((option) => option.id === field.value) || null

  return (
    <Autocomplete
      className="grow"
      disabled={fetching || !!error}
      filterOptions={(filters, params) =>
        filters.filter(
          (option) =>
            option.name.toLowerCase().includes(params.inputValue.toLowerCase()) ||
            option.ncciCode.toLowerCase().includes(params.inputValue.toLowerCase())
        )
      }
      getOptionLabel={(option) => `${option.name} (${option.ncciCode})`}
      onChange={(_e, value) => setValue(value ? value.id : null)}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          value={selectedOption}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {fetching ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          label="Workers Comp Code"
          placeholder="Workers comp code"
          error={errorShouldBeDisplayed}
          helperText={error}
        />
      )}
      renderOption={({ id, ...props }, option) => (
        <li key={id} {...props}>
          <div className="flex justify-between w-full">
            <div>{option.name}</div>
            <div className="text-gray-600">{option.ncciCode}</div>
          </div>
        </li>
      )}
      value={selectedOption ?? null}
    />
  )
}

import { clsx } from "clsx"
import { FC } from "react"
import { AiOutlineWarning } from "react-icons/ai"
import { BiX } from "react-icons/bi"
import { ProgressBar } from "./ProgressBars/ProgressBar"

type ImageUploadPreviewProps = {
  imageSrc: string
  progress?: number
  error?: boolean
  onDelete?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  className?: string
}

export const ImageUploadPreview: FC<ImageUploadPreviewProps> = ({
  imageSrc,
  onDelete,
  progress,
  error = false,
  className,
}) => {
  const loading = (!!progress || progress === 0) && progress < 1

  return (
    <div className={clsx("relative grow aspect-square", className)}>
      <img
        src={imageSrc}
        alt="Uploaded file"
        className={clsx("object-contain size-full rounded-md border bg-white", loading && "opacity-40")}
      />
      {error && (
        <div className="absolute top-0 left-0 flex justify-center items-center size-full">
          <AiOutlineWarning className="text-red-500 size-10" />
        </div>
      )}
      {onDelete && (
        <button
          type="button"
          className="absolute top-0.5 right-0.5 flex items-center justify-center rounded-full bg-white text-gray-800 shadow size-5"
          onClick={(event) => {
            event.preventDefault()
            onDelete(event)
          }}
        >
          <BiX className="size-3" />
        </button>
      )}
      {loading && !error && (
        <div className="absolute bottom-1 w-full px-2">
          <ProgressBar percentage={progress} suppressPercentLabel={true} className="h-1 py-[3px]" />
        </div>
      )}
    </div>
  )
}

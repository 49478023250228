import { clsx } from "clsx"
import { CSSProperties, FC, useMemo } from "react"
import { useWindowSize } from "../../hooks/useWindowSize"
import { TailwindIcon } from "../../types/tailwind"
import { LabelWithNoBar } from "./LabelWithNoBar"

type ProgressBarProps = {
  bgColor?: string
  Icon?: TailwindIcon
  isComplete?: boolean
  itemLabel?: string
  max?: number
  value?: number
  percentage?: number
  textColor?: string
  className?: string
  suppressPercentLabel?: boolean
  customNoMaxSetLabel?: string
}

type BarState =
  | "default"
  | "shifted"
  | "over"
  | "overShifted"
  | "complete"
  | "completeShifted"
  | "completeOver"
  | "completeOverShifted"

type BarStyles = {
  [key in BarState]: {
    fillBarClassNames: string
    fillBarTextClassNames: string
    fillBarBorderClassNames: string
    fillBarBorderStyles: CSSProperties
    fillBarContainerStyles: CSSProperties
    fillBarTextStyles?: CSSProperties
  }
}

export const ProgressBar: FC<ProgressBarProps> = ({
  bgColor = "bg-blue-600",
  Icon,
  isComplete,
  itemLabel,
  max,
  value,
  percentage,
  textColor = "text-blue-800",
  className = "",
  suppressPercentLabel = false,
  customNoMaxSetLabel = "",
}) => {
  const { width } = useWindowSize()

  const percentageFull = percentage ? percentage : max && value ? value / max : 0

  const percentageOver = useMemo(() => {
    if (percentage && percentage > 1) return percentage - 1
    if (!max || !value) return 0
    if (value <= max) return 0
    return 1 - max / value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [max, percentage, width, value])

  const isHalfFull = useMemo(() => {
    const barValue = value || 0
    const barMax = max || 0

    if (percentage && percentage > 1) {
      return percentage > 1.5
    }
    if (percentage && percentage <= 1) {
      return percentage > 0.5
    }

    return barValue > barMax ? barValue > barMax * 2 : barValue > barMax / 2
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, max, percentage, value])

  const barState = useMemo<BarState>(() => {
    const barValue = value || 0
    const barMax = max || 0
    const isOver = percentage ? percentage > 1 : barValue > barMax

    if (isOver && isHalfFull && isComplete) return "completeOver"
    if (isOver && !isHalfFull && isComplete) return "completeOverShifted"
    if (isComplete && !isHalfFull) return "complete"
    if (isComplete && isHalfFull) return "completeShifted"
    if (!isOver && isHalfFull) return "shifted"
    if (isOver && isHalfFull) return "over"
    if (isOver && !isHalfFull) return "overShifted"

    return "default"
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, max, percentage, isHalfFull, isComplete, percentageOver, width])

  const styles = useMemo<BarStyles>(
    () => ({
      default: {
        fillBarClassNames: clsx(bgColor, percentageFull < 1 && "rounded-r-none"),
        fillBarTextClassNames: textColor,
        fillBarBorderClassNames: "",
        fillBarBorderStyles: {},
        fillBarContainerStyles: {
          backgroundColor: "transparent",
          left: `${Math.max(0, percentageFull * 100)}%`,
        },
      },
      shifted: {
        fillBarClassNames: clsx(bgColor, percentageFull < 1 && "rounded-r-none"),
        fillBarTextClassNames: `text-white w-auto`,
        fillBarBorderClassNames: "",
        fillBarBorderStyles: { right: `${(1 - percentageFull) * 100}%` },
        fillBarContainerStyles: {
          justifyContent: "end",
          right: `${100 - percentageFull * 100}%`,
        },
      },
      over: {
        fillBarClassNames: `bg-orange-600`,
        fillBarTextClassNames: `text-white`,
        fillBarBorderClassNames: "border-l-2 border-white w-auto",
        fillBarBorderStyles: {},
        fillBarContainerStyles: {
          left: `${percentageOver < 1 ? (1 - percentageOver) * 100 : 1}%`,
        },
      },
      overShifted: {
        fillBarClassNames: `bg-orange-600`,
        fillBarTextClassNames: `text-white`,
        fillBarBorderClassNames: "border-r-2 border-white w-auto",
        fillBarBorderStyles: { right: `${(1 - percentageOver) * 100}%` },
        fillBarContainerStyles: {},
        fillBarTextStyles: {
          left: `${percentageOver * 100}%`,
        },
      },
      completeOver: {
        fillBarClassNames: `bg-green-600`,
        fillBarTextClassNames: `text-white`,
        fillBarBorderClassNames: "border-l-2 border-white w-auto",
        fillBarBorderStyles: {},
        fillBarContainerStyles: {
          left: `${(1 - percentageOver) * 100}%`,
        },
      },
      completeOverShifted: {
        fillBarClassNames: `bg-green-600`,
        fillBarTextClassNames: `text-white`,
        fillBarBorderClassNames: "border-r-2 border-white w-auto",
        fillBarBorderStyles: { right: `${percentageOver * 100}%` },
        fillBarContainerStyles: {},
      },
      complete: {
        fillBarClassNames: clsx("bg-green-600", percentageFull < 1 && "rounded-r-none"),
        fillBarTextClassNames: "text-green-600",
        fillBarBorderClassNames: "",
        fillBarBorderStyles: {},
        fillBarContainerStyles: { left: `${percentageFull * 100}%` },
      },
      completeShifted: {
        fillBarClassNames: clsx("bg-green-600", percentageFull < 1 && "rounded-r-none"),
        fillBarTextClassNames: `text-white w-auto`,
        fillBarBorderClassNames: "",
        fillBarBorderStyles: { right: `${(1 - percentageFull) * 100}%` },
        fillBarContainerStyles: {},
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bgColor, percentageFull, percentageOver, textColor, width]
  )

  if (suppressPercentLabel && !percentage && !value && !max) {
    return null
  }

  if (!max && !percentage) {
    return <LabelWithNoBar>{customNoMaxSetLabel || `No set ${itemLabel}`}</LabelWithNoBar>
  }

  if (value && !max) {
    return (
      <LabelWithNoBar textColor={textColor}>
        <div className="flex items-center gap-1">
          {Icon && <Icon className={clsx("size-4", textColor)} />}
          <span>
            {value} {itemLabel}
          </span>
        </div>
      </LabelWithNoBar>
    )
  }

  return (
    <div
      className={clsx(
        className,
        "relative bg-gray-100 w-full py-2 px-3 rounded overflow-hidden font-bold h-6 text-[13px]"
      )}
    >
      <div
        className={clsx(
          "absolute h-full top-0 left-0 rounded",
          "transition-all duration-500 ease-in delay-200 origin-left",
          styles[barState].fillBarClassNames
        )}
        style={{
          width: `${percentageFull * 100}%`,
        }}
      ></div>
      <div
        className={clsx(
          `absolute flex items-center size-full top-0 transition-all duration-500 ease-in delay-500 origin-left`,
          styles[barState].fillBarTextClassNames
        )}
        style={{
          ...styles[barState].fillBarContainerStyles,
          maxWidth: `${percentageFull * 100}%`,
        }}
      >
        <span
          className="px-2 flex gap-1 items-center relative top-[2px]"
          style={{ ...styles[barState].fillBarTextStyles }}
        >
          {Icon && <Icon className="size-4 relative bottom-[2px]" />}
          {!suppressPercentLabel && (
            <span className="pb-1">{new Intl.NumberFormat().format(Math.round(percentageFull * 100))}%</span>
          )}
          <span className="2xl:inline pb-1">
            {percentage && (
              <span>
                {` `} {itemLabel}
              </span>
            )}
          </span>
        </span>
        <div
          className={clsx(
            "absolute size-full top-0 transition-all duration-500 ease-in delay-500 origin-left",
            styles[barState].fillBarBorderClassNames
          )}
          style={{
            ...styles[barState].fillBarBorderStyles,
            maxWidth: `${percentageFull * 100}%)`,
          }}
        />
      </div>
    </div>
  )
}

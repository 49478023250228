type Field = { [key: string]: unknown }

export function sortByFieldAscending<T>(callback: (value: T) => string | number) {
  return (a: Field, b: Field) => {
    const aValue = callback(a as T)
    const bValue = callback(b as T)

    if (aValue > bValue) return 1
    if (aValue < bValue) return -1

    return 0
  }
}
